import restApiSlice from "../../services/rest-api/slice"

const invoicesRestApiSlice = restApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getInvoices: builder.query({
      query: () => ({ url: "/invoices/me", method: "GET" }),
      transformResponse: (response) => {
        return response.data.map((invoice) => {
          return {
            ...invoice,
            summary: {
              ...invoice.summary,
              amountPaid: invoice.summary.amountPaid >= 0 ? invoice.summary.amountPaid : 0,
              amountOutstanding: invoice.summary.amountOutstanding >= 0 ? invoice.summary.amountOutstanding : invoice.summary.invoice.total,
            },
          }
        })
      },
    }),
    exportInvoices: builder.mutation({
      query: ({ year, month }) => ({
        url: '/invoices/export',
        method: 'POST',
        body: {
          payload: {
            year: parseInt(year, 10),
            month: parseInt(month, 10)
          }
        },
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      transformResponse: (response) => response.data,
    }),
  }),
})

export const { useGetInvoicesQuery, useExportInvoicesMutation } = invoicesRestApiSlice

export default invoicesRestApiSlice.reducer
