import React, { createContext, useContext, useMemo, useState, useCallback } from "react"
import PropTypes from "prop-types"
import { useQuotationContext } from "../../../../../state/quotation-provider"

const ValidationContext = createContext()

function ValidationProvider({ children }) {
  const { setValidationFlowWizard, setShowValidateCreateLabelModal } = useQuotationContext()
  const [validatedQuotations, setValidatedQuotations] = useState([])
  const [invalidQuotations, setInvalidQuotations] = useState([])
  const [successfullyPaidQuotations, setSuccessfullyPaidQuotations] = useState([])
  const [unsuccessfullyPaidQuotations, setUnsuccessfullyPaidQuotations] = useState([])
  const [modalAlert, setModalAlert] = useState()
  const [isPaymentLoading, setIsPaymentLoading] = useState(false)

  // Clear all quotation-related states
  const clearAllQuotationStates = useCallback(() => {
    setValidatedQuotations([])
    setInvalidQuotations([])
    setSuccessfullyPaidQuotations([])
    setUnsuccessfullyPaidQuotations([])
    setModalAlert(undefined)
    setIsPaymentLoading(false)
  }, [])

  const clearModalAlert = useCallback(() => {
    setModalAlert(undefined)
  }, [])

  const closeValidationModal = useCallback(() => {
    clearAllQuotationStates()
    setShowValidateCreateLabelModal(false)
    setValidationFlowWizard(undefined)
  }, [setShowValidateCreateLabelModal, setValidationFlowWizard])

  const removeValidatedQuotation = useCallback((quotation) => {
    setValidatedQuotations(prevValidatedQuotations => {
      const newValidatedQuotations = prevValidatedQuotations.filter(
        validQuotation => validQuotation.rateRequestId !== quotation.rateRequestId
      )

      // If removing the last quotation, close the modal
      if (newValidatedQuotations.length === 0) {
        closeValidationModal()
      }

      return newValidatedQuotations
    })

    // Also remove from invalid quotations if present
    setInvalidQuotations(prev =>
      prev.filter(q => q.rateRequestId !== quotation.rateRequestId)
    )
  }, [])

  const handlePaymentSuccess = useCallback((successfulQuotations, failedQuotations) => {
    // Clear validated quotations since they've been processed
    setValidatedQuotations([])
    setSuccessfullyPaidQuotations(successfulQuotations)
    setUnsuccessfullyPaidQuotations(failedQuotations)
  }, [])

  const value = useMemo(
    () => ({
      validatedQuotations,
      setValidatedQuotations,
      invalidQuotations,
      setInvalidQuotations,
      modalAlert,
      clearModalAlert,
      isPaymentLoading,
      setIsPaymentLoading,
      successfullyPaidQuotations,
      setSuccessfullyPaidQuotations,
      unsuccessfullyPaidQuotations,
      setUnsuccessfullyPaidQuotations,
      removeValidatedQuotation,
      setModalAlert,
      closeValidationModal,
      handlePaymentSuccess,
      clearAllQuotationStates
    }),
    [
      validatedQuotations,
      invalidQuotations,
      modalAlert,
      isPaymentLoading,
      successfullyPaidQuotations,
      unsuccessfullyPaidQuotations,
      clearModalAlert,
      removeValidatedQuotation,
      closeValidationModal,
      handlePaymentSuccess,
    ]
  )

  return (
    <ValidationContext.Provider value={value}>
      {children}
    </ValidationContext.Provider>
  )
}

ValidationProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const useValidationContext = () => {
  const context = useContext(ValidationContext)
  if (!context) {
    throw new Error('useValidationContext must be used within a ValidationProvider')
  }
  return context
}

export default ValidationProvider
