import React from "react"
import { Box, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import QuotesList from "../../../quotes-list"
import TotalPrice from "./components/total-price"
import { useValidationContext } from "../../../../state/provider"
import ButtonsRow from "./components/buttons-row"
import LoadingVideo from "../../../loading-video"
import TermsAndConditions from "../../../../../../../../quotations/components/payment/components/payment-details/components/terms-and-conditions"
import { pxToRem } from "../../../../../../../../../soe-theme/src/utils"

function ConfirmPayment() {
  const { t } = useTranslation("orderManagement")

  const { validatedQuotations, isPaymentLoading, removeValidatedQuotation } = useValidationContext()

  const handleRemoveQuotation = (quotation) => {
    return removeValidatedQuotation(quotation)
  }

  return (
    <Box style={{ display: "flex", flexDirection: "column", width: "100%", height: "100%", justifyContent: "space-between", alignItems: "center" }}>
      {
        !isPaymentLoading
        ?
        <>
          <TotalPrice quotes={validatedQuotations} />
          <Box sx={{ overflowY: "auto", flexGrow: 1, width: "100%" }}>
            <QuotesList quotes={validatedQuotations} removable onRemove={handleRemoveQuotation} />
          </Box>
          <Box sx={{display: "flex", width: "100%", justifyContent: "center"}}>
            <TermsAndConditions />
          </Box>
          <ButtonsRow />
        </>
        :
        <>
          <LoadingVideo />
          <Typography variant="h6" paddingBottom={pxToRem(20)}>{t("modals.validation.stayOnPage")}</Typography>
        </>
      }
    </Box>
  )
}


export default ConfirmPayment
