import React, { useState } from "react"
import PropTypes from "prop-types"
import { Box } from "@mui/material"
import { Clear, PersonSearch, Undo } from "@mui/icons-material"
import AddressSummary from "../../../../../../address-summary"
import { useQuotationTableContext } from "../../../../../state/quotations-table-provider"
import AddressSearch from "../address-search"
import useUpdateQuotation from "../../../../../hooks/update-quote"
import { pxToRem } from "../../../../../../../soe-theme/src/utils"

function RecipientInfo({ order, recipientComplete }) {
  const { addressBookAddresses } = useQuotationTableContext()
  const { updateQuotation } = useUpdateQuotation()

  const [showSearchBar, setShowSearchBar] = useState(false)

  if (!order) return null

  const shouldShowAddressSummary = order.recipient && recipientComplete && !showSearchBar
  const shouldShowClearIcon = order.recipient && recipientComplete

  const handleSelectAddress = async (address) => {
    await updateQuotation({
      order: {
        ...order,
        recipient: address,
      },
    })
    setShowSearchBar(false)
  }

  const handleSearchClick = (e) => {
    e.stopPropagation()
    setShowSearchBar(true)
  }

  const handleCloseSearchBar = (e) => {
    e.stopPropagation()
    setShowSearchBar(false)
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: pxToRem(5) }}>
      {
        shouldShowAddressSummary ?
        <Box sx={{display: "flex", flexDirection: "row", gap: pxToRem(10), alignItems: "center"}}>
          <PersonSearch
            color="secondary"
            onClick={handleSearchClick}
            sx={{
              cursor: 'pointer',
              '&:hover': {
                opacity: 0.8
              }
            }}
          />
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <AddressSummary addressSummary={order.recipient} />
          </Box>
        </Box>:
        <Box sx={{ display: "flex", flexDirection: "column", gap: pxToRem(10) }}>
          {
            shouldShowClearIcon &&
          <Box sx={{display: "flex", justifyContent: "flex-end"}}>
            <Clear
              fontSize="small"
              onClick={handleCloseSearchBar}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  opacity: 0.8
                }
              }}
            />
          </Box>
          }
          <AddressSearch addressBookAddresses={addressBookAddresses} onSelect={handleSelectAddress} />
        </Box>
      }
    </Box>
  )
}

RecipientInfo.propTypes = {
  order: PropTypes.instanceOf(Object),
  recipientComplete: PropTypes.bool.isRequired,
}

RecipientInfo.defaultProps = {
  order: undefined,
}

export default RecipientInfo
