import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { Box } from "@mui/material"
import QuotesList from "../../../quotes-list"
import ModalButtonsRow from "../../../../../generic/components/modal-buttons-row"
import { useValidationContext } from "../../../../state/provider"
import { ROUTES } from "../../../../../../../../../utils/constants"
import PaidQuotationsToggle from "./paid-quotations-toggle"
import PaymentErrorList from "../../../payment-error-list"

function PaymentResults() {
  const { t } = useTranslation("orderManagement")
  const [showValidOrInvalidQuotations, setShowValidOrInvalidQuotations] = useState("paid")
  const navigate = useNavigate();

  const { successfullyPaidQuotations, unsuccessfullyPaidQuotations, closeValidationModal } = useValidationContext()

  const handleLeftButtonClick = () => {
    closeValidationModal()
  }

  const handleRightButtonClick = () => {
    navigate(ROUTES.SHIPMENTS)
  }

  return (
    <Box style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <PaidQuotationsToggle value={showValidOrInvalidQuotations} onChange={setShowValidOrInvalidQuotations} validCount={successfullyPaidQuotations.length || 0} invalidCount={unsuccessfullyPaidQuotations.length || 0} />
      <Box sx={{ overflowY: "auto", flexGrow: 1, width: "100%" }}>
        {
          showValidOrInvalidQuotations === "paid" ?
            <QuotesList quotes={successfullyPaidQuotations} />
            :
            <PaymentErrorList quotes={unsuccessfullyPaidQuotations} />
        }
      </Box>
      <ModalButtonsRow onLeftButtonClick={handleLeftButtonClick} onRightButtonClick={handleRightButtonClick} leftButtonLabel={t("modals.validation.buttons.goOrders.label")} rightButtonLabel={t("modals.validation.buttons.schedulePickup.label")} />
    </Box>
  )
}

export default PaymentResults
