import React from "react";
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

function ValidQuotationsToggle({ value, onChange, validCount, invalidCount }) {
  const { t } = useTranslation("orderManagement")

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center" }}>
      <ToggleButtonGroup
        fullWidth
        color="primary"
        exclusive
        value={value}
        onChange={(event)=>onChange(event.target.value)}
      >
        <ToggleButton value="valid">{t("toggle.validQuotations.valid.label")}  ({validCount})</ToggleButton>
        <ToggleButton value="invalid">{t("toggle.validQuotations.invalid.label")} ({invalidCount})</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  )
}

ValidQuotationsToggle.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  validCount: PropTypes.number.isRequired,
  invalidCount: PropTypes.number.isRequired,
}

export default ValidQuotationsToggle;
