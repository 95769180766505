import React, { useState } from "react"
import PropTypes from "prop-types"
import { Box } from "@mui/material"
import { Clear, PersonSearch } from "@mui/icons-material"

import AddressSummary from "../../../../../../address-summary"
import { useQuotationTableContext } from "../../../../../state/quotations-table-provider"
import AddressSearch from "../address-search"
import useUpdateQuotation from "../../../../../hooks/update-quote"
import { pxToRem } from "../../../../../../../soe-theme/src/utils"

function ShipperInfo({ order, shipperComplete }) {
  const { addressBookAddresses } = useQuotationTableContext()
  const { updateQuotation } = useUpdateQuotation()
  const [showSearchBar, setShowSearchBar] = useState(false)

  if (!order) return null

  const shouldShowAddressSummary = order.shipper && shipperComplete && !showSearchBar
  const shouldShowClearIcon = order.shipper && shipperComplete

  const handleSelectAddress = async (address) => {
    await updateQuotation({
      order: {
        ...order,
        shipper: address,
      },
    })
    setShowSearchBar(false)
  }
  const handleSearchClick = (e) => {
    e.stopPropagation()
    setShowSearchBar(true)
  }

  const handleCloseSearchBar = (e) => {
    e.stopPropagation()
    setShowSearchBar(false)
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: pxToRem(5) }}>
      {shouldShowAddressSummary ?
          <Box sx={{display: "flex", flexDirection: "row", gap: pxToRem(10), alignItems: "center"}}>
          <PersonSearch
            color="secondary"
            onClick={handleSearchClick}
            sx={{
              cursor: 'pointer',
              '&:hover': {
                opacity: 0.8
              }
            }}
          />
          <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <AddressSummary addressSummary={order.shipper} />
          </Box>
        </Box>:
        <Box sx={{ display: "flex", flexDirection: "column", gap: pxToRem(10) }}>
          {
            shouldShowClearIcon &&
            <Box sx={{display: "flex", justifyContent: "flex-end"}}>
              <Clear
                fontSize="small"
                onClick={handleCloseSearchBar}
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    opacity: 0.8
                  }
                }}
              />
            </Box>
          }
          <AddressSearch addressBookAddresses={addressBookAddresses} onSelect={handleSelectAddress} />
        </Box>
      }
    </Box>
  )
}

ShipperInfo.propTypes = {
  order: PropTypes.instanceOf(Object),
  shipperComplete: PropTypes.bool.isRequired,
}

ShipperInfo.defaultProps = {
  order: undefined,
}

export default ShipperInfo
