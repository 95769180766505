import React from "react"
import PropTypes from "prop-types"
import { Box, Typography } from "@mui/material"
import { totalPackages } from "../../../../../../../utils/packages"
import { pxToRem } from "../../../../../../../soe-theme/src/utils"

function PiecesInfo({ packages, packagesComplete, onClick }) {
  const handleOnClick = (e) => {
    e.stopPropagation()
    onClick()
  }

  return (
    <Box
      onClick={handleOnClick}
      sx={{
        display: "flex",
        // flexDirection: "column",
        width: "100%",
        height: "100%",
        alignItems: "center",
        padding: "10%",
        cursor: "pointer",
        "&:hover": {
          borderRadius: pxToRem(6),
          backgroundColor: "rgba(183, 226, 237, 1)",
        }
      }}
    >
      <Typography
        sx={{color: (!packages || !packagesComplete) ? "red" : "inherit"}}
      >
        {totalPackages(packages?.pieces)}
      </Typography>
    </Box>
  )
}


PiecesInfo.propTypes = {
  packages: PropTypes.instanceOf(Object),
  packagesComplete: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
}

PiecesInfo.defaultProps = {
  packages: undefined,
}

export default PiecesInfo
