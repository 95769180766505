import React, { useState } from "react"
import PropTypes from "prop-types"
import { useTheme } from "@mui/material/styles"
import Box from "@mui/material/Box"
import SearchIcon from "@mui/icons-material/Search"
import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import InputAdornment from "@mui/material/InputAdornment"
import { useTranslation } from "react-i18next"
import AutoCompletePaper from "../../../../../../../soe-theme/src/components/auto-complete-paper"
import AddressAutocompleteOption from "../../../../../../quotations/components/address-autocomplete-option"
import { AddressSearchGroupsText } from "../../../../../../../constants/quotationsConstants"
import mapAddress from "../../../../../utils/map"

function AddressSearch({
     addressBookAddresses,
     onSelect,
   }) {
  const { t } = useTranslation("fullAddressForm")
  const customTheme = useTheme()

  const [searchValue, setSearchValue] = useState("")
  const [addressesList, setAddressesList] = useState([])

  const handleAddressAutoCompleteChange = (_, value) => {
    if (value) {
      onSelect(mapAddress(addressBookAddresses.find((entry) => entry.id === value.id)))
    }
  }

  const handleAddressAutoCompleteInputChange = (event, value) => {
    setSearchValue(value)

    if (value && value.trim()) {
      setAddressesList(
        addressBookAddresses
          // ?.filter((entry) => (entry.type ? entry.type : AddressTypeEnum.Shipping) === addressType)
          ?.map((addressBookItem) =>
            Object({
              id: addressBookItem.id,
              companyName: addressBookItem.companyName || "",
              personName: addressBookItem.personName || "",
              address: Array([
                ...(addressBookItem.addressLine1 ? [" ".concat(addressBookItem.addressLine1)] : []),
                ...(addressBookItem.addressLine2 ? [" ".concat(addressBookItem.addressLine2)] : []),
                ...(addressBookItem.city ? [" ".concat(addressBookItem.city)] : []),
                ...(addressBookItem.provinceCode || addressBookItem.provinceName
                  ? [" ".concat(addressBookItem.provinceCode || addressBookItem.provinceName)] : []),
                ...(addressBookItem.countryCode ? [" ".concat(addressBookItem.countryCode)] : []),
                ...(addressBookItem.postalCode ? [" ".concat(addressBookItem.postalCode)] : []),
              ]),
              origin: AddressSearchGroupsText.AddressBook,
            })
          )
          ?.filter((entry) =>
            entry.companyName.concat(entry.personName)
              .concat(entry.address.join(" "))
              .toLowerCase()
              .includes(value.toLowerCase())
          )
      )
    } else {
      setAddressesList([])
    }
  }

  return (
    <Box component="div" my={customTheme.utils.pxToThemeSpacing(2)} onClick={e=>{e.stopPropagation()}}>
      <Autocomplete
        id="addressAutoComplete"
        options={addressesList || []}
        getOptionLabel={(option) => option && option.address && option.address.join(" ")}
        PaperComponent={AutoCompletePaper}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            // placeholder={placeholder || t("fields.addressSearch.label")}
            variant="standard"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
          />
        )}
        isOptionEqualToValue={(option, value) => {
          return option.id === value.id
        }}
        renderOption={(props, option) => (
          <AddressAutocompleteOption
            key={option.id}
            renderOptionProps={props}
            currentAddress={option}
          />
        )}
        filterOptions={(options) => options}
        onChange={handleAddressAutoCompleteChange}
        onInputChange={handleAddressAutoCompleteInputChange}
        noOptionsText={searchValue ? t("fields.addressSearch.noOptionsText") : t("fields.addressSearch.startTypingAddress")}
        autoComplete
        size="small"
      />
    </Box>
  )
}

AddressSearch.propTypes = {
  addressBookAddresses: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    companyName: PropTypes.string,
    personName: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    provinceCode: PropTypes.string,
    provinceName: PropTypes.string,
    countryCode: PropTypes.string,
    postalCode: PropTypes.string,
    type: PropTypes.string
  })),
  onSelect: PropTypes.func.isRequired,
}

AddressSearch.defaultProps = {
  addressBookAddresses: []
}

export default AddressSearch
