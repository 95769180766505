import round from "lodash/round"

export const totalPackages = (pieces) => {
  if (!pieces || !Array.isArray(pieces)) return 0
  return pieces.reduce((accumulator, { quantity }) => accumulator + quantity, 0)
}
export const totalWeight = (pieces) => {
  if (!pieces) return 0
  return pieces.reduce((accumulator, piece) => accumulator + round(piece.weight * piece.quantity, 2), 0)
}
