const mapAddress = (address) => ({
  id: address?.id || undefined,
  companyName: address?.companyName || "",
  personName: address?.personName || "",
  addressLine1: address?.addressLine1 || "",
  addressLine2: address?.addressLine2 || "",
  city: address?.city || "",
  provinceName: address?.provinceName || "",
  provinceCode: address?.provinceCode || "",
  countryCode: address?.countryCode || "CA",
  postalCode: address?.postalCode || "",
  residential: address?.residential || false,
  phoneNumber: address?.phoneNumber || "",
  emailAddress: address?.emailAddress || "",
  timezone: address?.timezone || undefined,
  validationProviderId: address?.validationProviderId || undefined,
})

export default mapAddress
