export const SHOPIFY_PARAMS = ['session', 'hmac', 'host', 'id_token', 'embedded', 'shop'];

export const getShopifyParams = (searchParams) => {
  const returnTo = searchParams.get('returnTo');
  const shopifyApp = searchParams.get('shopifyApp');
  return { returnTo, shopifyApp };
};

export const extractShopifyParams = (searchParams) => {
  const params = {};
  SHOPIFY_PARAMS.forEach(param => {
    if (searchParams.has(param)) {
      params[param] = searchParams.get(param);
    }
  });
  return params;
}; 