import React from "react"
import PropTypes from "prop-types"
import { Box, Typography } from "@mui/material"
import { totalWeight } from "../../../../../../../utils/packages"
import { IMPERIAL, METRIC } from "../../../../../../../services/units-conversion"
import { pxToRem } from "../../../../../../../soe-theme/src/utils"

function WeightInfo({ packages, packagesComplete, onClick }) {
  if (!packagesComplete || !packagesComplete) return null

  const handleOnClick = (e) => {
    e.stopPropagation()
    onClick()
  }

  return (
    <Box
      onClick={handleOnClick}
      sx={{
        display: "flex",
        // flexDirection: "column",
        width: "100%",
        height: "100%",
        alignItems: "center",
        padding: "10%",
        cursor: "pointer",
        "&:hover": {
          borderRadius: pxToRem(6),
          backgroundColor: "rgba(183, 226, 237, 1)",
        }
      }}
    >
      <Typography>
        {totalWeight(packages?.pieces).toFixed(1)} {packages?.unitOfMeasure === IMPERIAL && "lb"}
        {packages?.unitOfMeasure === METRIC && "kg"}
      </Typography>
    </Box>
  )
}


WeightInfo.propTypes = {
  packages: PropTypes.instanceOf(Object),
  packagesComplete: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
}

WeightInfo.defaultProps = {
  packages: undefined,
}

export default WeightInfo
