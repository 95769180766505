import restApiSlice from "../rest-api/slice"
import { getAddressSearchText } from "../../utils/search"

export const clientsRestApiSlice = restApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getClients: builder.query({
      query: () => ({ url: "/clients", method: "GET" }),
      providesTags: ["Clients"],
      transformResponse: (response) =>
        response.data.map((client) => {
          return {
            ...client,
            addressSearchText: getAddressSearchText(client.company?.clientAddress || client.clientAddress),
            billingSearchText: client?.billing?.type || "",
          }
        }),
    }),
    getServices: builder.query({
      query: (companyId) => ({ url: `/clients/${companyId}/services`, method: "GET" }),
      transformResponse: (response) => response.data,
    }),
  }),
})

export const { useGetClientsQuery, useGetServicesQuery } = clientsRestApiSlice
