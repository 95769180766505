import React, { createContext, useContext, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"

const QuotationContext = createContext()

// TODO: clean up and separate into different Providers (separation of concerns)

function QuotationProvider({ children }) {
  const [showRateRequestForm, setShowRateRequestForm] = useState(false);
  const [showOrderDetailsModal, setShowOrderDetailsModal] = useState(false)
  const [showValidateCreateLabelModal, setShowValidateCreateLabelModal] = useState(false)
  const [rateRequestStepWizard, setRateRequestStepWizard] = useState();
  const [validationFlowWizard, setValidationFlowWizard] = useState()
  const [currentEditingQuotation, setCurrentEditingQuotation] = useState();
  const [selectedQuotations, setSelectedQuotations] = useState([])
  const [alert, setAlert] = useState(undefined)
  const [snackbar, setSnackBar] = useState(undefined) // {message: "", severity: "warning"}
  const [targetSection, setTargetSection] = useState(null);


  const setOrderId = (orderId) => {
    setCurrentEditingQuotation((prev) => ({
      ...prev,
      orderId,
    }))
  }

  const setShipper = (shipperData) => {
    setCurrentEditingQuotation((prev) => ({
      ...prev,
      shipper: {
        ...prev.shipper,
        id: shipperData?.id || undefined,
        companyName: shipperData?.companyName || undefined,
        personName: shipperData?.personName || undefined,
        addressLine1: shipperData?.addressLine1 || undefined,
        addressLine2: shipperData?.addressLine2 || undefined,
        city: shipperData?.city || undefined,
        provinceName: shipperData?.provinceName || undefined,
        provinceCode: shipperData?.provinceCode || undefined,
        countryCode: shipperData?.countryCode || undefined,
        postalCode: shipperData?.postalCode || undefined,
        residential: shipperData?.residential,
        phoneNumber: shipperData?.phoneNumber || undefined,
        emailAddress: shipperData?.emailAddress || undefined,
        validationProviderId: shipperData?.validationProviderId || undefined,
        timezone: shipperData?.timezone || undefined,
      }
    }));
  }

  const setRecipient = (recipientData) => {
    setCurrentEditingQuotation((prev) => ({
      ...prev,
      recipient: {
        ...prev.recipient,
        id: recipientData?.id || undefined,
        companyName: recipientData?.companyName || undefined,
        personName: recipientData?.personName || undefined,
        addressLine1: recipientData?.addressLine1 || undefined,
        addressLine2: recipientData?.addressLine2 || undefined,
        city: recipientData?.city || undefined,
        provinceName: recipientData?.provinceName || undefined,
        provinceCode: recipientData?.provinceCode || undefined,
        countryCode: recipientData?.countryCode || undefined,
        postalCode: recipientData?.postalCode || undefined,
        residential: recipientData?.residential,
        phoneNumber: recipientData?.phoneNumber || undefined,
        emailAddress: recipientData?.emailAddress || undefined,
        validationProviderId: recipientData?.validationProviderId || undefined,
        timezone: recipientData?.timezone || undefined,
      }
    }));
  };

  const setPackages = (packageData) => {
    setCurrentEditingQuotation((prev) => ({
      ...prev,
      packages: {
        ...prev.packages,
        unitOfMeasure: packageData?.unitOfMeasure,
        pieces: packageData?.pieces,
      }
    }))
  }

  const setShippingOptions = (shippingOptionsData) => {
    const newShippingOptions = {
      shippingOptions: shippingOptionsData,
    }
    if (newShippingOptions.shippingOptions?.shippingDate) {
      newShippingOptions.shippingOptions.shippingDate = shippingOptionsData?.shippingDate?.split(" ")[0]
    }
    setCurrentEditingQuotation((prev)=> ({
      ...prev,
      ...newShippingOptions,
    }))
  }

  const setBillingOptions = (billingOptionsData) => {
    setCurrentEditingQuotation((prev) => ({
      ...prev,
      billingOptions: billingOptionsData,
    }))
  }

  const setFreightOptions = (freightOptionsData) => {
    setCurrentEditingQuotation((prev) => ({
      ...prev,
      freightOptions: freightOptionsData,
    }))
  }

  const setBroker = (brokerData) => {
    setCurrentEditingQuotation((prev) => ({
      ...prev,
      broker: brokerData
        ? {
            id: brokerData?.id || undefined,
            companyName: brokerData?.companyName || undefined,
            personName: brokerData?.personName || undefined,
            addressLine1: brokerData?.addressLine1 || undefined,
            addressLine2: brokerData?.addressLine2 || undefined,
            city: brokerData?.city || undefined,
            provinceName: brokerData?.provinceName || undefined,
            provinceCode: brokerData?.provinceCode || undefined,
            countryCode: brokerData?.countryCode || undefined,
            postalCode: brokerData?.postalCode || undefined,
            residential: brokerData?.residential,
            phoneNumber: brokerData?.phoneNumber || undefined,
            emailAddress: brokerData?.emailAddress || undefined,
            validationProviderId: brokerData?.validationProviderId || undefined,
          }
        : undefined,
    }))
  }

  const setInternationalOptions = (internationalOptionsData) => {
    setCurrentEditingQuotation((prev) => ({
      ...prev,
      internationalOptions: internationalOptionsData,
    }))
  }

  const setBuyer = (buyerData) => {
    setCurrentEditingQuotation((prev) => ({
      ...prev,
      buyer: buyerData
        ? {
            id: buyerData?.id || undefined,
            companyName: buyerData?.companyName || undefined,
            personName: buyerData?.personName || undefined,
            addressLine1: buyerData?.addressLine1 || undefined,
            addressLine2: buyerData?.addressLine2 || undefined,
            city: buyerData?.city || undefined,
            provinceName: buyerData?.provinceName || undefined,
            provinceCode: buyerData?.provinceCode || undefined,
            countryCode: buyerData?.countryCode || undefined,
            postalCode: buyerData?.postalCode || undefined,
            residential: buyerData?.residential,
            phoneNumber: buyerData?.phoneNumber || undefined,
            emailAddress: buyerData?.emailAddress || undefined,
            validationProviderId: buyerData?.validationProviderId || undefined,
          }
        : undefined,
    }))
  }

  const setCommercialInvoice = (commercialInvoiceData) => {
    setCurrentEditingQuotation((prev) => ({
      ...prev,
      commercialInvoice: commercialInvoiceData,
    }))
  }
  // TODO: create a function that handles setting the "showRateRequestForm" to false and also sets the "selectedQuotation" to false

// Update the setQuotationOpenModal function to store the target section
  const setQuotationOpenModal = (quotation, section) => {
    setCurrentEditingQuotation(quotation)
    setShowOrderDetailsModal(true)

    // If a specific section is provided, set the showRateRequestForm to true
    // and store the target section for later navigation
    if (section) {
      setShowRateRequestForm(true)
      setTargetSection(section)
    }
  }

  // Add a useEffect to handle navigation when rateRequestStepWizard becomes available
  useEffect(() => {
    if (rateRequestStepWizard && targetSection) {
      // Navigate to the specific section using the named step
      rateRequestStepWizard.goToNamedStep(targetSection)
      // Clear the target section to avoid unwanted navigation on future renders
      setTargetSection(null)
    }
  }, [rateRequestStepWizard, targetSection])

  const closeRateRequestModal = () => {
    setRateRequestStepWizard(undefined)
    setCurrentEditingQuotation(undefined)
    setShowOrderDetailsModal(false)
    setShowRateRequestForm(false)
  }


  // // TODO: update this to use the updateTableQuotation as a "middleware"

  const clearAlert = () => {
    setAlert(undefined)
  }

  const clearSnackBar = () => {
    setSnackBar(undefined)
  }

  const value = useMemo(
    () => ({
      showRateRequestForm,
      setShowRateRequestForm,
      rateRequestStepWizard,
      setRateRequestStepWizard,
      selectedQuotation: currentEditingQuotation,
      setSelectedQuotation: setCurrentEditingQuotation,
      setOrderId,
      setShipper,
      setRecipient,
      setPackages,
      setShippingOptions,
      setBillingOptions,
      setFreightOptions,
      setBroker,
      setInternationalOptions,
      setBuyer,
      setCommercialInvoice,
      closeRateRequestModal,
      setQuotationOpenModal,
      showOrderDetailsModal,
      setShowOrderDetailsModal,
      alert,
      setAlert,
      clearAlert,
      showValidateCreateLabelModal,
      setShowValidateCreateLabelModal,
      validationFlowWizard,
      setValidationFlowWizard,
      snackbar,
      clearSnackBar,
      setSnackBar
    }),
    [showRateRequestForm, rateRequestStepWizard, currentEditingQuotation, showOrderDetailsModal, showValidateCreateLabelModal, selectedQuotations, alert, validationFlowWizard, snackbar]
  );

  return (
    <QuotationContext.Provider value={value}>
      {children}
    </QuotationContext.Provider>
  );
}

QuotationProvider.propTypes = {
  children: PropTypes.node.isRequired, // Validate that children is a valid React node
}

export const useQuotationContext = () => {
  return useContext(QuotationContext);
}

export default QuotationProvider
