import React, { memo, useEffect } from "react"
import { useTranslation } from "react-i18next"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import PropTypes from "prop-types"
import { Tooltip, Typography } from "@mui/material"
import Checkbox from "@mui/material/Checkbox"
import DateTimeService from "../../../../../services/date-time"
import DropdownSelect from "./components/dropdown-select"
import useOrderValidation from "../../../hooks/use-order-validation"
import ShipperInfo from "./components/shipper-info"
import RecipientInfo from "./components/recipient-info"
import PiecesInfo from "./components/pieces-info"
import WeightInfo from "./components/weight-info"
import QuoteInfo from "./components/quote-info"
import { pxToRem } from "../../../../../soe-theme/src/utils"
import ActionButtonsContainer from "./components/buttons/action-buttons-container"
import DeleteQuotationButton from "./components/buttons/delete-quotation-button"
import useUpdateQuotation from "../../../hooks/update-quote"
import { useQuotationTableContext } from "../../../state/quotations-table-provider"
import { useQuotationContext } from "../../../state/quotation-provider"

// TODO: when switching between pages, updateTableQuotation executes which sets the "quote" to undefined. The quote should persist page change
const OrderRow = memo(({ order, ...props }) => {
  const { t } = useTranslation("orderManagement")
  const { updatedSelectedQuotations, selectedQuotations, updateTableQuotation, isAtSelectionLimit } = useQuotationTableContext()
  const { updateQuotation } = useUpdateQuotation()
  const { shipperComplete, recipientComplete, packagesComplete, isOrderComplete, isLoading: isQuotationValidationLoading, errors } = useOrderValidation(order)
  const isRowSelected = selectedQuotations.some((quotation) => order.rateRequestId === quotation.rateRequestId);
  const { setQuotationOpenModal } = useQuotationContext()

  const handleCheckboxClick = (event) => {
    event.stopPropagation();
    updatedSelectedQuotations(order)
  };

  const handleSelectClick = async (event) => {
    await updateQuotation({
      order: {
        ...order,
        shippingType: event.target.value,
      },
    })
  }

  const openOrderModalSection = (section) =>{
    setQuotationOpenModal(order, section)
  }

    const isDisabled = !isRowSelected && isAtSelectionLimit

    useEffect(() => {
    updateTableQuotation(
      {
        ...order,
        isComplete: isOrderComplete,
      },
      true // the "true" argument is a flag to not invalidate the row's current quote
    )
  }, [isOrderComplete])
  return (
    <TableRow
      {...props}
      sx={() => ({
        // backgroundColor: isOrderComplete ? "white" : theme.palette.warning.darker,
        backgroundColor: "white",
        ":hover": {
          backgroundColor: "rgba(183, 226, 237, 0.5)",
        }
      })}
    >
      <TableCell
        sx={(theme)=>({
          borderLeft: !isOrderComplete
            ? `4px solid ${theme.palette.error.main}`
            : `4px solid white`,
        })}
      >
        {
        isDisabled ? (
          <Tooltip title={t("buttons.selectOrder.tooltip.maxReached")}>
            <span style={{ display: "flex" }}>
              <Checkbox
                hidden={isQuotationValidationLoading} // hide checkbox when validation is loading
                disabled
                checked={isRowSelected}
                onClick={handleCheckboxClick}
              />
            </span>
          </Tooltip>
          ) :
          (
          <Checkbox
            hidden={isQuotationValidationLoading} // hide checkbox when validation is loading
            checked={isRowSelected}
            onClick={handleCheckboxClick}
          />
        )
        }
      </TableCell>
      <TableCell>
        <Typography>{order.orderId ?? ""}</Typography>
      </TableCell>
      <TableCell>
        <ShipperInfo order={order} shipperComplete={shipperComplete} />
      </TableCell>
      <TableCell>
        <RecipientInfo order={order} recipientComplete={recipientComplete} />
      </TableCell>
      <TableCell>
        <PiecesInfo packages={order.packages} packagesComplete={packagesComplete} onClick={()=> openOrderModalSection("packages")} />
      </TableCell>
      <TableCell>
        <WeightInfo packages={order.packages} packagesComplete={packagesComplete} onClick={()=> openOrderModalSection("packages")} />
      </TableCell>
      <TableCell>
        <Typography>{order.createdDate ? DateTimeService.toLocaleDateString(order.createdDate) : null}</Typography>
      </TableCell>
      <TableCell>
        <DropdownSelect onChange={handleSelectClick} value={order.shippingType} tooltipText={t("dropdowns.shippingOptions.tooltip")} />
      </TableCell>
      <TableCell style={{ minWidth: pxToRem(100), maxWidth: pxToRem(150) }}>
        <QuoteInfo order={order} />
      </TableCell>
      <TableCell style={{ minWidth: pxToRem(100) }}>
        <ActionButtonsContainer isQuotationValidationLoading={isQuotationValidationLoading} isOrderComplete={isOrderComplete} order={order} errors={errors} />
      </TableCell>
      <TableCell>
        <DeleteQuotationButton order={order} />
      </TableCell>
    </TableRow>
  )
}
)

OrderRow.propTypes = {
  order: PropTypes.instanceOf(Object).isRequired,
  ...TableRow.propTypes,
}

OrderRow.defaultProps = {
  ...TableRow.defaultProps,
};

export default OrderRow
