import restApiSlice from "../../services/rest-api/slice"

export const addressBookRestApiSlice = restApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAddressBook: builder.query({
      query: () => ({ url: "/addressbook/me", method: "GET" }),
      providesTags: ["AddressBookItems"],
      transformResponse: (response) => response.data,
    }),
    fetchAddressBook: builder.mutation({
      query: () => ({ url: "/addressbook/me", method: "GET" }),
      transformResponse: (response) => response.data,
    }),
    addAddressBookItem: builder.mutation({
      query: (payload) => ({ url: `/addressbook/me`, method: "POST", body: payload }),
      invalidatesTags: (result, error, arg) => {
        const { isDefaultShipper, isDefaultBroker } = arg
        if (isDefaultShipper || isDefaultBroker) return ["AddressBookItems", "UserProfile"]
        return ["AddressBookItems"]
      },
    }),
    editAddressBookItem: builder.mutation({
      query: ({ id, payload }) => ({ url: `/addressbook/me/${id}`, method: "PUT", body: payload }),
      invalidatesTags: (result, error, arg) => {
        const { isDefaultShipper, isDefaultBroker } = arg.payload
        if (isDefaultShipper || isDefaultBroker) return ["AddressBookItems", "UserProfile"]
        return ["AddressBookItems"]
      },
    }),
    deleteAddressBookItem: builder.mutation({
      query: (id) => ({ url: `/addressbook/me/${id}`, method: "DELETE" }),
      invalidatesTags: ["AddressBookItems"],
      transformResponse: (response) => response.data,
    }),
  }),
})

export const { useGetAddressBookQuery, useFetchAddressBookMutation, useAddAddressBookItemMutation, useEditAddressBookItemMutation, useDeleteAddressBookItemMutation } = addressBookRestApiSlice
