import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Box } from "@mui/material"
import QuotesList from "../../../quotes-list"
import ModalButtonsRow from "../../../../../generic/components/modal-buttons-row"
import { useQuotationContext } from "../../../../../../../state/quotation-provider"
import { useValidationContext } from "../../../../state/provider"
import ValidQuotationsToggle from "./components/valid-quotations-toggle"
import InvalidQuotesList from "../../../invalid-quotes-list"

function ValidationResults() {
  const { t } = useTranslation("orderManagement")
  const [showValidOrInvalidQuotations, setShowValidOrInvalidQuotations] = useState("valid")

  const { validationFlowWizard } = useQuotationContext()

  const { validatedQuotations, invalidQuotations, closeValidationModal } = useValidationContext()

  const handleLeftButtonClick = () => {
    validationFlowWizard.goToNamedStep("confirm-payment")
  }

  const handleRightButtonClick = () => {
    closeValidationModal()
  }

  return (
    <Box style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <ValidQuotationsToggle value={showValidOrInvalidQuotations} onChange={setShowValidOrInvalidQuotations} validCount={validatedQuotations.length || 0} invalidCount={invalidQuotations.length || 0} />
      <Box sx={{ overflowY: "auto", flexGrow: 1, width: "100%" }}>
        {
          showValidOrInvalidQuotations === "valid" ?
            <QuotesList quotes={validatedQuotations} />
            :
            <InvalidQuotesList quotes={invalidQuotations} />
        }
      </Box>
      <ModalButtonsRow onLeftButtonClick={handleLeftButtonClick} onRightButtonClick={handleRightButtonClick} leftButtonLabel={t("modals.validation.buttons.continue.label")} rightButtonLabel={t("modals.validation.buttons.cancel.label")} />
    </Box>
  )
}

export default ValidationResults
