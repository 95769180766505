// Basic shipping options
const shippingOptions = ["cheapest", "fastest"]

// Carrier codes mapped to their specific service codes
const CARRIER_CODES = {
  'federal-express': ['federal-express', 'FEDEX_INTERNATIONAL_PRIORITY_EXPRESS', 'FEDEX_INTERNATIONAL_PRIORITY', 'FEDEX_EXPRESS_SAVER', 'fedex-freight'],
  'purolator-express': ['purolator-express', 'PurolatorExpress', 'purolator-freight'],
  'canada-post': ['canada-post'],
  'dhl-express': ['dhl-express'],
  'canpar-express': ['canpar-express'],
  'day-ross': ['day-ross-commerce', 'day-ross-freight'],
  'nationex': ['nationex'],
  'gls': ['gls-parcel', 'gls-freight'],
  'ups-parcel': ['ups-parcel']
};

/**
 * Get shipping options based on available services and translation function
 * @param {Array} services - Array of available service codes
 * @param {Function} t - Translation function
 * @returns {Array} Array of option objects with label and value properties
 */
const getShippingOptions = (services, t) => {
  // Create base options from the shipping options (cheapest, fastest)
  const baseOptions = shippingOptions.map((option) => ({
    label: t(`dropdowns.shippingOptions.${option}.label`),
    value: option,
  }));

  // If services is undefined, only show the base options (cheapest, fastest...)
  if (!services || services.length === 0) {
    return baseOptions;
  }

  // For each carrier in services, add all its service codes
  const serviceOptions = [];
  const addedServices = new Set(); // Track added services to avoid duplicates

  // First collect all available carrier keys that are in the services array
  const availableCarriers = Object.keys(CARRIER_CODES).filter(carrier =>
    services.includes(carrier)
  );

  // For each available carrier, add all their service codes
  availableCarriers.forEach(carrier => {
    CARRIER_CODES[carrier].forEach(serviceCode => {
      if (!addedServices.has(serviceCode)) {
        serviceOptions.push({
          label: t(`dropdowns.shippingOptions.${serviceCode}.label`, { defaultValue: serviceCode }),
          value: serviceCode
        });
        addedServices.add(serviceCode);
      }
    });
  });

  // Add any services from the services array that haven't been added yet
  services.forEach(serviceCode => {
    if (!addedServices.has(serviceCode)) {
      serviceOptions.push({
        label: t(`dropdowns.shippingOptions.${serviceCode}.label`, { defaultValue: serviceCode }),
        value: serviceCode
      });
      addedServices.add(serviceCode);
    }
  });

  return [...baseOptions, ...serviceOptions];
};

export { shippingOptions, CARRIER_CODES, getShippingOptions }