import React, { useState } from "react"
import { Box, Card } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"
import ImportCSVButton from "./components/import-csv-button"
import BulkActionsContainer from "./components/bulk-actions-container"
import FileUploadDrawer from "../drawers/file-upload-drawer"
import SelectDeselectAllButtons from "./components/select-deselect-all-buttons"
import SearchBar from "./components/search-bar"
import { pxToRem } from "../../../../soe-theme/src/utils"
import RefreshButton from "../../../refresh-button"
import DownloadFile from "../../../download-file"
import UploadTemplate from "../../../../assets/downloadable-files/Inex Upload Template.xlsx"
import UploadInstructions from "../../../../assets/downloadable-files/Inex - OQMS Read Me.pdf"

function HeaderButtons() {
  const [showFileUploadDrawer, setShowFileUploadDrawer] = useState(false);
  const { t } = useTranslation("orderManagement")


  const handleRefresh = async () => {
    window.location.reload()
  }


  const theme = useTheme()

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        padding: `${theme.spacing(3)} ${theme.spacing(3)}`,
        gap: pxToRem(15),
        overflow: "visible",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "center", height: "100%" }}>
        <Box sx={{ display: "flex", flexDirection: "row", gap: theme.spacing(1) }}>
          <SelectDeselectAllButtons />
          <DownloadFile file={UploadInstructions} label={t("buttons.uploadInstructions.label")} filename="Inex-OQMS-Read_Me.pdf" />
          <DownloadFile file={UploadTemplate} label={t("buttons.downloadTemplate.label")} filename="Inex-Upload_Template.xlsx"/>
          <ImportCSVButton setShowFileUploadDrawer={setShowFileUploadDrawer} />
        </Box>
        <RefreshButton tooltipText={t("buttons.refresh.tooltip")} tooltipPlacement="left" onRefresh={handleRefresh}/>
      </Box>
      <Box sx={{ height: "100%", width: "100%", display: "flex", flexDirection: "row", justifyContent: "center", alignContent: "center" }}>
        <Box sx={{ flex: 1 }} />
        <Box sx={{ flex: 1 }}>
          <SearchBar />
        </Box>
        <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
          <BulkActionsContainer />
        </Box>
      </Box>
      <FileUploadDrawer setShowFileUploadDrawer={setShowFileUploadDrawer} showFileUploadDrawer={showFileUploadDrawer} />
    </Card>
  )
}

export default HeaderButtons;
