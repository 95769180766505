// REACT
import React, { useState } from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import Paper from "@mui/material/Paper"
import Skeleton from "@mui/material/Skeleton"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableRow from "@mui/material/TableRow"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import { useSelector } from "react-redux"
import EnhancedTableHead from "../enhanced-table-head"
import getComparator from "../../utils/sorting"
import Invoice from "./components/invoice"

import { useGetInvoicesQuery } from "./slice"
import ExportInvoices from "./components/export-invoices"

function Invoices() {
  const { t } = useTranslation("invoices")
  const currentCompany = useSelector((state) => state.user?.company)
  const customTheme = useTheme()

  const [order, setOrder] = useState("desc")
  const [orderBy, setOrderBy] = useState("createdDate")

  const { data: invoices, isFetching } = useGetInvoicesQuery()

  const headCells = [
    {
      id: "invoiceNumber",
      disablePadding: false,
      label: t("headers.invoiceNumber"),
    },
    {
      id: "createdDate",
      disablePadding: false,
      label: t("headers.createdDate"),
    },
    {
      id: "dueDate",
      disablePadding: false,
      label: t("headers.dueDate"),
    },
    {
      id: "totalAmount",
      disablePadding: false,
      label: t("headers.totalAmount"),
    },
    {
      id: "totalPaid",
      disablePadding: false,
      label: t("headers.totalPaid"),
    },
    {
      id: "totalUnpaid",
      disablePadding: false,
      label: t("headers.totalUnpaid"),
    },
    {
      id: "paymentStatus",
      disablePadding: false,
      label: t("headers.paymentStatus"),
    },
    {
      id: "menu",
    },
  ]

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  return (
    <Box component="div">
      <Card variant="outlined" square sx={{ height: "auto", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: `${customTheme.spacing(2)} ${customTheme.spacing(2)}` }}>
        <CardHeader title={t("title")} sx={{p:0}} />
        {currentCompany?.featureFlags?.exportAllInvoices === true && <ExportInvoices invoices={invoices} />}
      </Card>
      <Box sx={(theme) => ({ ml: theme.utils.pxToRem(14), mr: theme.utils.pxToRem(14), width: "auto" })}>
        <Paper sx={{ width: "100%" }}>
          <TableContainer>
            <Table size="small" sx={(theme) => ({ borderCollapse: "separate", borderSpacing: "0px 10px", backgroundColor: theme.palette.backgroundNeutralUnderground1.main })}>
              <EnhancedTableHead headCells={headCells} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
              <TableBody>
                {isFetching ? (
                  <>
                    {Array(3)
                      .fill()
                      .map((_, index) => {
                        return (
                          <TableRow key={"".concat(index)}>
                            <TableCell colSpan={12}>
                              <Skeleton variant="rectangular" height={50} width="100%" />
                            </TableCell>
                          </TableRow>
                        )
                      })}
                  </>
                ) : (
                  invoices
                    .slice()
                    .sort(getComparator(order, orderBy))
                    .map((invoice, index) => {
                      return <Invoice key={"".concat(index)} invoice={invoice} />
                    })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </Box>
  )
}

export default Invoices
