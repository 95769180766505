import { useTranslation } from "react-i18next"
import { useOnAccountPaymentMutation } from "../../slice"
import { useValidationContext } from "../../components/modals/validate-create-label-modal/state/provider"
import { useQuotationTableContext } from "../../state/quotations-table-provider"

// TODO: maybe this should be separated in 2 separate hooks, one for payments and one for UI/state changes
function useOnAccountPayment() {
  const { t } = useTranslation("orderManagement")
  const [placeOrder, { isLoading: isPlacingOrderLoading, error: placeOrderError }] = useOnAccountPaymentMutation();

  const {
    deleteQuotation
  } = useQuotationTableContext();
  const {
    setIsPaymentLoading,
    validatedQuotations,
    handlePaymentSuccess,
    setModalAlert,
  } = useValidationContext()


  const makeOnAccountPayment = async (orders) => {
    const response = await placeOrder({
      payload: {
        skipPayment: "true",
        requests: orders.map(order=>{
          return {
            rateResponseId: order.quote.rateResponseId,
            rateRequestId: order.rateRequestId,
          }
        })
      },
    }).unwrap() // TODO: do I need to unwrap?
    return response
  }

  const processPayments = async () => {
    try {
      setIsPaymentLoading(true)
      const response = await makeOnAccountPayment(validatedQuotations)

      const successfulPaymentResults = response?.details?.successfulShipments || []
      const failedPaymentResults = response?.details.failedShipments || []

      const successfulQuotations = validatedQuotations.filter(quotation =>
        successfulPaymentResults.some(payment => payment.rateRequestId === quotation.rateRequestId)
      );

      const failedQuotations = failedPaymentResults.length > 0
        ? validatedQuotations
              .map(quotation => {
                const failedPayment = failedPaymentResults.find(
                  payment => payment.rateRequestId === quotation.rateRequestId
                );
                if (failedPayment) {
                  return {
                    ...quotation,
                    errorMessage: failedPayment.error
                  };
                }
                return null;
              })
              .filter(Boolean)
          : [];

      handlePaymentSuccess(successfulQuotations, failedQuotations)
      successfulPaymentResults.map(result => deleteQuotation({ rateRequestId: result.rateRequestId }))

      // Handle success/failure alerts
      if (failedPaymentResults.length > 0) {
        setModalAlert({
          severity: 'error',
          message: t("alert.error.payment.label")
        });
      } else {
        setModalAlert({
          severity: 'success',
          message: t("alert.success.payment.label")
        });
      }
    } catch (error) {
      setModalAlert({
        severity: 'error',
        message: t("alert.error.paymentTimeout.label"),
      })
    } finally {
      setIsPaymentLoading(false)
    }
  }

  // const processPayments = async () => {
  //   try {
  //     setIsPaymentLoading(true)
  //
  //     // Process payments
  //     const response = await makeOnAccountPayment(validatedQuotations)
  //
  //     const successfulPaymentResults = response?.details?.successfulShipments || []
  //     const failedPaymentResults = response?.details.failedShipments || []
  //
  //     // FE: optimistic update
  //     await Promise.allSettled(
  //       successfulPaymentResults.map(result =>
  //         deleteQuotation({ rateRequestId: result.rateRequestId })
  //       )
  //     );
  //
  //     const successfulQuotations = validatedQuotations.filter(quotation =>
  //       successfulPaymentResults.some(payment => payment.rateRequestId === quotation.rateRequestId)
  //     );
  //     setSuccessfullyPaidQuotations(successfulQuotations);
  //
  //     const failedQuotations = failedPaymentResults.length > 0
  //       ? validatedQuotations
  //           .map(quotation => {
  //             const failedPayment = failedPaymentResults.find(
  //               payment => payment.rateRequestId === quotation.rateRequestId
  //             );
  //
  //             if (failedPayment) {
  //               return {
  //                 ...quotation,
  //                 errorMessage: failedPayment.error
  //               };
  //             }
  //             return null;
  //           })
  //           .filter(Boolean)
  //       : [];
  //
  //     setUnsuccessfullyPaidQuotations(failedQuotations);
  //
  //     // Handle success/failure alerts
  //     if (failedPaymentResults.length > 0) {
  //       setModalAlert({
  //         severity: 'error',
  //         message: t("alert.error.payment.label")
  //       });
  //     } else {
  //       setModalAlert({
  //         severity: 'success',
  //         message: t("alert.success.payment.label")
  //       });
  //     }
  //
  //     return true;
  //
  //   } catch (error) {
  //     return setModalAlert({
  //       severity: 'error',
  //       message: t("alert.error.paymentTimeout.label"),
  //     });
  //   } finally {
  //     setIsPaymentLoading(false)
  //   }
  // }

  return {
    processPayments,
    isPlacingOrderLoading,
    placeOrderError
  }
}

export default useOnAccountPayment;