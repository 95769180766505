import React from 'react';
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

function PaidQuotationsToggle({ value, onChange, validCount, invalidCount }) {
  const { t } = useTranslation("orderManagement")

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center" }}>
      <ToggleButtonGroup
        fullWidth
        color="primary"
        exclusive
        value={value}
        onChange={(event)=>onChange(event.target.value)}
      >
        <ToggleButton value="paid">{t("toggle.paymentResult.successful.label")} ({validCount})</ToggleButton>
        <ToggleButton value="unpaid">{t("toggle.paymentResult.failed.label")} ({invalidCount})</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  )
}

PaidQuotationsToggle.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  validCount: PropTypes.number.isRequired,
  invalidCount: PropTypes.number.isRequired,
}

export default PaidQuotationsToggle;
