import React, { useMemo } from "react"
import PropTypes from "prop-types"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import { Tooltip } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useQuotationTableContext } from "../../../../../state/quotations-table-provider"
import { getShippingOptions } from "../../../../../utils/shippingOptions"

function DropdownSelect({ onChange, value, tooltipText, ...props }) {
  const { t } = useTranslation("orderManagement")
  const { services } = useQuotationTableContext()

  // Use useMemo to prevent recalculating options on every render
  const options = useMemo(() => {
    return getShippingOptions(services, t);
  }, [services]);

  return (
    <Tooltip title={tooltipText} placement="left-end">
      <Select
        variant="outlined"
        name="shippingType"
        onClick={(event) => event.stopPropagation()}
        onChange={onChange}
        value={value}
        size="small"
        defaultValue=""
        {...props}
      >
        {options.map((option, index) => (
          <MenuItem
            key={index}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </Tooltip>
  )
}

DropdownSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
  ...Select.propTypes,
}

DropdownSelect.defaultProps = {
  ...Select.defaultProps,
  tooltipText: ``,
}

export default DropdownSelect