import { useValidateManyMutation } from "../../../quotations/components/payment/components/payment-validation/slice"

const useOrderValidations = () => {
  const [validate, { data, isLoading, error }] = useValidateManyMutation()

  const runValidation = async (orders) => {
    const res = await validate(orders.map((order) => order.quote?.rateResponseId))
    return res.data
  }

  return { runValidation, data, isLoading, error };
}

export default useOrderValidations;
