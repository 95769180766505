import React from "react"
import Snackbar from "@mui/material/Snackbar"
import { Alert, Typography } from "@mui/material"
import { useQuotationContext } from "../../state/quotation-provider"

function SnackbarContainer() {
  const { snackbar, clearSnackBar } = useQuotationContext()

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    clearSnackBar()
  }

  return (
    <Snackbar
      open={!!snackbar}
      autoHideDuration={5000}
      onClose={handleAlertClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
      <Alert
        onClose={handleAlertClose}
        severity={snackbar?.severity || "warning"}
        variant="filled"
        sx={{ width: '100%' }}
        hidden={!snackbar} // prevents UI bug where orange Alert flashes onClose
      >
        <Typography
          sx={{
            color: snackbar?.severity === "success" ? "white" : "inherit"
          }}
        >
          {snackbar?.message}
        </Typography>
      </Alert>
    </Snackbar>
  )
}

export default SnackbarContainer
