import React, { useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import CircularProgress from "@mui/material/CircularProgress"
import { Box, Button } from "@mui/material"
import { useExportInvoicesMutation } from "../../slice"
import { pxToRem } from "../../../../soe-theme/src/utils"
import CustomDatePicker from "../../../date-picker"
import { createAlert } from "../../../../reducers/alertReducer"

function ExportInvoices({ invoices }) {
  const [exportInvoices] = useExportInvoicesMutation()
  const dispatch = useDispatch();
  const { t } = useTranslation("invoices")
  const [isExporting, setIsExporting] = useState(false)
  const [fromDate, setFromDate] = useState(null)
  const [open, setOpen] = useState(false)

  const handleExportAll = async () => {
    if (!fromDate) return
    try {
      setIsExporting(true)
      const year = fromDate.getFullYear()
      const month = fromDate.getMonth() + 1
      const response = await exportInvoices({ year, month })
      if (!response?.data?.url) throw new Error('No download URL received')
      window.open(response?.data?.url, '_blank')
    } catch (error) {
      dispatch(createAlert(t("alert.error.failedExport.label")));
    } finally {
      setIsExporting(false)
    }


  }

  if (!invoices.length) return null

  return (
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: pxToRem(5) }}>
      <CustomDatePicker
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        label={t("buttons.selectDate.label")}
        value={fromDate}
        onChange={(newValue) => setFromDate(newValue)}
        openTo="month"
        views={['month', 'year']}
        inputProps={{
          readOnly: true,
          style: { cursor: 'pointer' },
          onClick: ()=> setOpen(true),
        }}
      />
      <Button
        variant="contained"
        onClick={handleExportAll}
        disabled={!fromDate || isExporting}
        startIcon={isExporting ? <CircularProgress size={pxToRem(20)} color="inherit" /> : null}
      >
        {t("buttons.exportAll.label")}
      </Button>
    </Box>
  )
}

export default ExportInvoices

ExportInvoices.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    createdDate: PropTypes.string.isRequired,
  })),
}

ExportInvoices.defaultProps = {
  invoices: [],
}